@use '../config/' as *;
.waf-livetiming {
    .waf-head {
        height: 35rem;
    }
    &.waf-component {
        @extend %p-0;
    }
    @extend %tab-section-common;
    @extend %page-head-blue-bg;
    @extend %tabs-underline;
    .waf-body {
        background-color: transparent;
    }
    .card {
        @extend %border-radius-l;
        @extend %bg-neutral-0;
        @include border(1, neutral-20);
        + .card {
            @extend %mt-4;
        }
        &:last-of-type {
            @extend %mb-14;
        }
        &-title {
            @extend %mb-1;
        }
        &-head {
            @extend %p-4;
            @include border(1, neutral-20, 10, bottom);
            .event-item {
                @extend %px-0;
            }
        }
    }
    .no-data-container,
    .tab-container-note {
        @extend %m-4;
        @extend %border-radius-l;
        @extend %p-10-4;
        @extend %bg-neutral-5;
        .text {
            @extend %text-center;
            @extend %title-40;
            @extend %mb-0;
        }
    }
    .tab-container-note {
        .title {
            @extend %mb-0;
            @extend %text-center;
        }
        .text {
            @extend %title-50;
            &:last-of-type {
                @extend %d-block;
            }
        }
        .icon {
            &::before {
                @include icon(circle-plus, 20);
            }
        }
    }
    .meta {
        @extend %neutral-70;
        @extend %flex;
        @extend %gap-2;
        &::before {
            line-height: 1;
        }
        &-origin {
            &::before {
                @include icon(map-pin, 20);
            }
        }
        &-category {
            &::before {
                @include icon(trophy, 20);
            }
        }
        + .meta {
            @extend %mt-2;
        }
    }
    .type {
        &-meta {
            @extend %flex-n-c;
        }
        &-title {
            font-weight: 700;
            @extend %primary-50;
        }
    }
    .event {
        &-list {
            @extend %py-6;
        }
        &-item {
            @extend %px-4;
            + .event-item {
                @extend %mt-8;
                @extend %pt-6;
                @include border(1, neutral-20, 10, top);
            }
        }
        &-title {
            font-weight: 700;
            @extend %mt-1;
            @extend %mb-0;
        }
        &-types {
            @extend %mt-6;
        }
        &-type {
            @extend %mt-4;
        }
    }
    .badge {
        @extend %px-3;
    }
    .tab {
        &-text {
            @extend %flex-n-c;
            @extend %gap-2;
        }
        &-name {
            &[aria-label="Incidents"] {
                .badge {
                    @extend %bg-notice-0;
                    @extend %notice-90;
                }
            }
        }
    }
    .tab-section {
        @extend %p-0;
        &-head {
            @extend %p-4;
        }
        &-text {
            @extend %neutral-70;
        }
        .tabs {
            margin-inline: 0;
            overflow-x: auto;
        }
        .point {
            flex-basis: 25.4rem;
        }
        .pos {
            flex-basis: 7.2rem;
        }
        .name,
        .nation,
        .lap,
        .diff {
            flex-basis: 26.6rem;
        }
        .start,
        .leg {
            flex-basis: 9.4rem;
        }
        .action,
        .status {
            flex-basis: 8rem;
            justify-content: center;
        }
        .fname,
        .lname {
            flex-basis: 12.8rem;
        }
        .table-head {
            .table-data {
                cursor: default;
                background-color: var(--neutral-5);
                .text {
                    color: var(--neutral-100);
                }
                &::after {
                    content: unset;
                }
                &:hover {
                    background-color: var(--neutral-5);
                }
            }
        }
        .incidents-tab {
            .name {
                flex-basis: 31.4rem;
            }
            .nation {
                flex-basis: 7.2rem;
            }
        }
        .incidents-tab,
        .leaderboard-tab, 
        .tracking-tab {
            .table-body {
                .name {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    @extend %gap-2;
                    .text {
                        @extend %body-s;
                        @extend %neutral-70;
                        @extend %flex-n-c;
                        @extend %gap-2;
                        &::before {
                            content: url("/static-assets/images/svg/penalty.svg?v=#{$image-version}");
                            width: 1.2rem;
                            height: 1.3rem;
                        }
                    }
                }
            }
        }
    }
    .name,
    .fname {
        .image {
            width: 4rem;
            min-width: 4rem;
            height: 4rem;
            aspect-ratio: 1 / 1;
            flex-shrink: 0;
            @extend %circle-radius;
            @include border(2, primary-50);
        }
    }
    .name .btn-text {
        @extend %primary-50;
    }
    .tab-section .fname a .text {
        @extend %primary-50;
    }
    .diff-text {
        font-weight: 700;
    }
    .action {
        justify-content: center;
    }
    .btn-add {
        width: 4.8rem;
        aspect-ratio: 1/1;
        @extend %flex-c-c;
        @extend %circle-radius;
        @include border(1, primary-50);
        &::before {
            @extend %primary-50;
            @include icon(plus, 13);
        }
        &:hover {
            border-color: var(--primary-55);
            &::before {
                @extend %primary-55;
            }
        }
        &.active {
            @extend %bg-informative-10;
            &::before {
                content: "\e898";
                font-size: 1.6rem;
            }
        }
        .btn-text {
            @extend %font-0;
        }
    }
    .btn-close {
        @include position(2.8rem, 2.8rem);
        &:hover {
            &::after {
                @extend %primary-55;
            }
        }
        &:focus {
            &::after {
                @extend %primary-85;
            }
        }
        &::after {
            @extend %primary-50;
            @include icon(close, 24);
        }
        .btn-text {
            @extend %font-0;
        }
    }
    .waf-select-box {
        max-width: 32rem;
        @extend %mt-6;
        @extend %mx-4;
        .select-box-wrap {
            display: none;
        }
        &.active {
            .select-box-wrap {
                display: block;
            }
        }
    }
    .live-sites,
    .live-coverage {
        @extend %event-card-small;
        .card-body {
            @extend %p-4;
        }
        .program {
            &-item {
                padding: var(--space-4);
                &:not(:last-child) {
                    margin-bottom: var(--space-2);
                }
            }
        }
    }
    .live-sites {
        .program {
            &-item {
                min-height: 15.2rem;
                background: var(--neutral-0);
                &::before {
                    content: unset;
                }
                &::after {
                    background: var(--neutral-0);
                }
            }
            &-content {
                max-width: 77%;
            }
        }
        .title {
            -webkit-line-clamp: 4;
        }
    }
    .live-coverage {
        .program {
            &-item {
                &::before {
                    opacity: 0.1;
                }
            }
        }
    }
    .table {
        &-responsive {
            width: auto;
            @extend %m-4;
        }
        &-data {
            flex-grow: 1;
        }
        &-body {
            .table-row {
                align-items: stretch;
            }
            .table-data {
                padding-block: var(--space-4);
                height: unset;
            }
            .text {
                &.diff-text {
                    font-weight: 700;
                }
            }
            .team {
                .table-data-wrap {
                    gap: var(--space-0);
                    @include flex-config(flex, column, center, flex-start);
                }
            }
        }
        &-data-wrap {
            .btn-text {
                @extend %text-left;
            }
        }
    }
    .modal {
        @extend %d-none;
        &.active {
            display: flex;
        }
        &-wrap {
            height: max-content;
            max-height: calc(var(--window-inner-height) - 15%);
            overflow-y: auto;
            padding-top: var(--space-22);
            @include custom-scroll();
            @extend %w-100;
            @extend %p-4;
            @extend %bg-neutral-0;
            @extend %border-radius-l;
            @extend %relative;
        }
        &-window {
            align-items: center;
            @extend %px-4;
            @include bg(neutral-100, 7);
        }
    }
    .player {
        &-thumbnail {
            width: 12.8rem;
            height: 12.8rem;
            aspect-ratio: 1/1;
            border: 0.5rem solid;
            order: -1;
            @extend %circle-radius;
            img {
                object-fit: cover;
                @extend %w-100;
                @extend %h-100;
                @extend %circle-radius;
            }
            &.men {
                border-color: var(--focus-0);
            }
            &.women {
                border-color: var(--secondary-70);
            }
        }
        &-origin {
            @extend %mt-8;
            @extend %flex-n-c;
            @extend %gap-2;
            img {
                width: 2.4rem;
                aspect-ratio: 1/1;
                height: 2.4rem;
                @extend %circle-radius;
            }
            .country-flag {
                @extend %flex-n-c;
                @extend %gap-2;
            }
            .country-flag,
            .date {
                @extend %text-l;
                @extend %neutral-70;
            }
            .date {
                @extend %flex-n-c;
                @extend %gap-2;
                &::before {
                    content: "";
                    width: 0.8rem;
                    aspect-ratio: 1/1;
                    @extend %bg-neutral-20;
                    @extend %circle-radius;
                }
            }
        }
        &-data {
            @extend %flex-column;
            @extend %mt-2;
            .label {
                @extend %body-lb;
                @extend %neutral-100;
            }
            .value {
                @extend %body-l;
                @extend %neutral-100;
            }
        }
        &-ranking-info {
            border-top: 0.1rem solid var(--neutral-20);
            @extend %mt-6;
            @extend %pt-6;
            @extend %flex-column;
            @extend %gap-4;
            .title {
                @extend %title-40;
            }
        }
        &-rank-item {
            @extend %flex-sb-c;
        }
        &-rank-title {
            @extend %body-l;
        }
        &-rank-count {
            @extend %title-50;
        }
        &-history-info {
            @include grid(3, var(--space-5));
            @extend %bg-neutral-5;
            @extend %p-4;
            @extend %border-radius-s;
            @extend %mt-6;
        }
        &-history-item {
            flex-direction: column-reverse;
            @extend %flex-c-c;
        }
        &-history-count {
            @extend %title-30;
            @extend %neutral-100;
        }
        &-history-title {
            @extend %text-l;
            @extend %neutral-70;
            @extend %text-center;
        }
        &-basic-info {
            @extend %flex-column;
        }
        &-title {
            @extend %mt-8;
            @extend %mb-0;
            @extend %title-30;
        }
        &-redirect {
            order: -1;
            @extend %mt-8;
        }
        &-detail-wrapper {
            @extend %mt-6;
            .table-responsive {
                @extend %m-0;
            }
        }
    }
    .item {
        + .item {
            @extend %mt-2;
        }
    }
    .profile-link {
        @include flex-config(inline-flex, row, null, center);
        @extend %primary-50;
        @extend %text-mb;
        &:after {
            @include icon(arrow-right, 19);
            @extend %ml-2;
        }
        &:hover {
            @extend %primary-55;
        }
        &:focus {
            @extend %primary-85;
        }
    }
    .waf-event-strip {
        &.waf-component {
            padding: var(--space-4);
            margin-bottom: 0;
        }
        .layout-wrapper {
            max-width: 100%;
        }
        .waf-head {
            border-radius: unset;
            background: transparent;
            &::before {
                content: unset;
            }
        }
        .head-tab {
            display: none;
        }
        .head-wrap {
            margin-block: 0;
            .title {
                margin-bottom: 0;
                color: var(--neutral-100);
                font-size: 2rem;
                &::before {
                    content: unset;
                }
            }
        }
        .waf-body {
            margin-top: var(--space-8);
        }
        .card {
            &-list {
                margin-right: -1.5rem;
            }
            &-head {
                padding: 0;
            }
        }
    }
    &.waf-mixed-relay {
        .tab-section {
            .leaderboard-tab {
                .team {
                    flex-basis: 20.4rem;
                }
                .nation {
                    flex-basis: 12rem;
                }
            }
            .startlist-tab {
                .team {
                    flex-basis: 37.2rem;
                }
                .nation {
                    flex-basis: 12.8rem;
                }
            }
        }
        .modal {
            .player {
                &-title,
                &-redirect {
                    order: -1;
                }
                &-title {
                    margin-top: 0;
                }
                &-redirect,
                &-origin {
                    margin-top: var(--space-6);
                }
                &-list {
                    @extend %mt-6;
                    @extend %pt-6;
                    @include border(1, neutral-20, 10, top);
                    .title {
                        @extend %mb-6;
                    }
                }
                &-item {
                    @extend %flex-n-c;
                    @extend %gap-6;
                    + .player-item {
                        @extend %pt-5;
                        @extend %mt-5;
                        @include border(1, neutral-20, 10, top);
                    }
                }
                &-name {
                    font-weight: 700;
                    @extend %primary-50;
                }
            }
        }
    }
}
@include mq(col-md) {
    .waf-livetiming {
        .modal {
            &-body {
                max-width: unset;
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-livetiming {
        .waf-head {
            height: 45rem;
        }
        .card {
            + .card {
                margin-top: var(--space-10);
            }
            &:last-of-type {
                margin-bottom: var(--space-24);
            }
            &-head {
                padding: var(--space-6) var(--space-8);
            }
        }
        .no-data-container,
        .tab-container-note {
            margin: var(--space-6);
            border-radius: 1.6rem;
            padding-inline: 20rem;
        }
        .meta {
            + .meta {
                margin-top: 0;
            }
        }
        .tab-container-note {
            @include flex-config(flex, row, center);
            .icon {
                margin-right: var(--space-1);
            }
            .text {
                font-size: 2.4rem;
                &:last-of-type {
                    display: inline;
                }
            }
        }
        .event {
            &-list {
                padding-block: var(--space-8);
            }
            &-meta {
                flex-wrap: wrap;
                gap: var(--space-6);
                @include flex-config(flex, row, null, center);
            }
            &-item {
                padding-inline: var(--space-6);
                + .event-item {
                    padding-top: var(--space-8)
                }
            }
            &-title {
                font-size: 2rem;
            }
            &-types {
                margin-top: var(--space-10);
            }
            &-type {
                margin-top: var(--space-6);
            }
        }
        .tab-section {
            padding: 0;
            border-radius: unset;
            .tabs {
                padding-inline: var(--space-6);
            }
            .table {
                &-header {
                    justify-content: center;
                }
            }
            &-head {
                padding: var(--space-6) var(--space-8);
            }
        }
        .waf-select-box {
            margin-inline: var(--space-6);
        }
        .live-sites,
        .live-coverage {
            .card-body {
                padding: var(--space-10);
            }
            .program {
                &-item {
                    min-height: 20rem;
                    &:not(:last-child) {
                        margin-bottom: var(--space-0);
                    }
                }
            }
        }
        .live-coverage {
            .program {
                &-item {
                    gap: 0;
                }
                &-content {
                    .text {
                        display: block;
                        color: var(--primary-50);
                    }
                    .title {
                        margin-top: var(--space-2);
                        margin-right: var(--space-2);
                    }
                }
            }
        }
        .table {
            &-responsive {
                margin: var(--space-6);
            }
        }
        .modal {
            &-wrap {
                overflow-y: unset;
                height: calc(var(--window-inner-height) - 8%);
                padding-inline: var(--space-12);
                padding-block: var(--space-23) var(--space-10);
            }
            &-window {
                padding-inline: var(--space-10);
            }
            &-body {
                max-height: 100%;
                overflow-y: auto;
                @include custom-scroll();
            }
        }
        .player {
            &-basic-info {
                position: relative;
            }
            &-profile-wrapper {
                display: flex;
                gap: var(--space-7);
            }
            &-ranking-info {
                margin-top: var(--space-8);
                padding-top: var(--space-8);
            }
            &-redirect {
                order: -1;
                margin-top: 0;
                @include position(13rem, 0);
            }
            &-detail-wrapper {
                width: 60%;
                margin-top: 0;
            }
            &-info-wrapper {
                width: 40%;
            }
        }
        .waf-event-strip {
            .waf-head {
                min-height: unset;
            }
            &.waf-component {
                padding: var(--space-6);
            }
            .head-wrap {
                .title {
                    font-size: 2.4rem;
                }
            }
            .card {
                &-list {
                    margin-right: 0;
                }
            }
        }
        &.waf-mixed-relay {
            .modal {
                .player {
                    &-redirect {
                        position: static;
                    }
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-livetiming {
        .tab-section {
            .point {
                flex-basis: 36%;
            }
            .pos {
                flex-basis: 6%;
            }
            .name,
            .nation,
            .lap,
            .diff {
                flex-basis: 19%;
            }
            .start,
            .action {
                flex-basis: 9%;
            }
            .leg {
                flex-basis: 7%;
            }
            &.player-detail-wrapper {
                .diff {
                    flex-basis: 58%;
                }
            }
            .status {
                flex-basis: 10%;
            }
            .incidents-tab {
                .name {
                    flex-basis: 43%;
                }
                .nation {
                    flex-basis: 10%;
                }
            }
            .startlist-tab {
                .fname,
                .lname,
                .yob,
                .nation {
                    flex-basis: 22.75%;
                }
            }
        }
        .player {
            &-redirect {
                top: 11rem;
            }
        }
        &.waf-mixed-relay {
            .tab-section {
                .leaderboard-tab {
                    .team {
                        flex-basis: 30%;
                    }
                    .nation {
                        flex-basis: 9%;
                    }
                }
                .startlist-tab {
                    .team {
                        flex-basis: 74%;
                    }
                    .nation {
                        flex-basis: 17%;
                    }
                }
            }
        }
    }
}
@include mq(col-desktop) {
    .waf-livetiming {
        .player {
            &-profile-wrapper {
                gap: var(--space-18);
            }
            &-info-wrapper {
                width: 30%;
            }
            &-detail-wrapper {
                width: 70%;
            }
        }
    }
}